import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/Banner"
import CategoryTag from "../components/CategoryTag"

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        category
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const { previous, next } = data
  const categoryTags = post.frontmatter.category

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <ArticleWrapper itemScope itemType="http://schema.org/Article">
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p css={date}>{post.frontmatter.date}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </ArticleWrapper>
      <Footer>
        {categoryTags ? categoryTags.map((tag, index) => {
          return (
            <span>
              <CategoryTag category={tag} />
            </span>
          )
        }) : ''}
      </Footer>
      <NavWrapper>
        <hr />
        <ul>
          <li className="prev">
            {previous && (
              <Link to={'/posts' + previous.fields.slug} rel="prev">
                {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li className="next">
            {next && (
              <Link to={'/posts' + next.fields.slug} rel="next">
                {next.frontmatter.title}
              </Link>
            )}
          </li>
        </ul>
      </NavWrapper>
      <Banner/>
    </Layout>
  )
}

const date = css`
  text-align: right;
  margin: 0;
`


const ArticleWrapper = styled.article`
  margin-bottom: 6rem;
  header {
    margin-bottom: 6rem;
  }
`

const Footer = styled.footer`
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row-reverse;
`

const NavWrapper = styled.nav`
margin-bottom: 6rem;
  ul {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
  a {
    color: var(--clr-grey-5);
    &:hover {
      color: var(--clr-grey-6);
    }
  }
  .prev {
    float: left;
    text-align: left;
    width: 48%;
  }
  .prev::before {
    content: "PREV";
    color: var(--clr-grey-7);
    display: block;
    font-size: 0.875em;
    margin-bottom: 3px;
  }
  .next {
    float: right;
    text-align: right;
    width: 48%;
  }
  .next::before {
    content: "NEXT";
    color: var(--clr-grey-7);
    display: block;
    font-size: 0.875em;
    margin-bottom: 3px;
  }
`

export default BlogPostTemplate


